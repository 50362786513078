export interface UserLocationDealer {
  postcode: string;
  crmDealerId: string;
  name: string;
  uniqueName: string;
}

export const getDealerByPostcode = (
  postcode: string
): Promise<UserLocationDealer> =>
  fetch(
    `/api/dealers?top=1&DepartmentName=Vehicle Sales&ActiveOnly=true&PostCode=${postcode}`
  )
    .then((res) => res.json())
    .then((res) => {
      let userDealer: UserLocationDealer = {
        name: 'Subaru Sydney City',
        crmDealerId: '122390',
        postcode: '2000',
        uniqueName: 'subaru-sydney-city',
      };
      if (Array.isArray(res) && res.length > 0) {
        userDealer = res[0];
        userDealer.postcode = postcode;
      }

      return userDealer;
    });
