import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import { IBasicRte, INav, INavItem, MegaNavContextType } from '../../store';
import styles from './index.module.scss';
import ancestorStyles from '../../index.module.scss';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '~/common/components/ui-elements/floating-dialog';
import { MegaNavContext } from '../..';
import { HeroCardBlock } from '~/macros/hero-card-v2';
import MegaNavCtas from '../ctas';
import {
  colorBlack,
  removeSpecialCharacters,
  toCamelCase,
} from '~/common/utils';
import { IconEnum, Modal, SvgIcon } from '~/common/components/ui-elements';
import digitalData from '~/common/services/data-layer-service';
import { usePortals } from 'react-portal-hook';
import { CallToAction } from '~/common/models';

export const Nav = memo((props: INav & { idx: number }) => {
  const { openIndex, setOpenIndex, isTablet } = React.useContext(
    MegaNavContext
  ) as MegaNavContextType;
  const ref = useRef<HTMLDivElement | null>(null);
  const [parentHeight, setParentHeight] = useState<number | null>(null);
  const parentRef = useRef<HTMLDivElement | null>(null);
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const previewRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [sectionScale, setSectionScale] = useState(1);
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });
  const [selectedIdx, setSelectedIdx] = useState(
    props.items?.findIndex(
      (x) => x.contentType === 'NavItem' && !(x as INavItem).hidden
    )
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setOpen(false);
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
        setParentHeight(null);
        setSectionScale(1);
      };

      handleResize();

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (open) {
      digitalData.pushWithCommonData(
        {
          event: '_formNavigate',
          form: {
            name: 'mega nav',
            stage: removeSpecialCharacters(props.text),
          },
        },
        true
      );
    } else {
      setOpenIndex(-1);
    }
  }, [open]);

  const calculateMinHeight = useCallback(() => {
    const vh = windowSize.height;
    return Math.max(0, vh * (isTablet ? 0.6 : 0.8) - 85); // 85px for desktop/tablet offset only, since no preview for mobile
  }, [windowSize.height]);

  const calculateMaxHeight = useCallback(() => {
    const vh = windowSize.height;
    return Math.max(0, vh * 0.8 - 85); // 85px for desktop/tablet offset only, since no preview for mobile
  }, [windowSize.height]);

  useEffect(() => {
    if (parentRef.current && sectionRef.current && previewRef.current) {
      const tallestChildHeight = Math.max(
        sectionRef.current?.scrollHeight || 0,
        previewRef.current?.scrollHeight || 0
      );
      const minHeight = calculateMinHeight();
      const maxHeight = calculateMaxHeight();
      const calculatedHeight = Math.min(
        Math.max(tallestChildHeight, minHeight),
        maxHeight
      );
      setParentHeight(calculatedHeight);
      if (sectionRef.current.scrollHeight > calculatedHeight)
        setSectionScale((prevScale) => prevScale - 0.01);
    }
  }, [
    parentRef.current?.offsetHeight,
    sectionRef.current?.scrollHeight,
    previewRef.current?.scrollHeight,
    calculateMinHeight,
    calculateMaxHeight,
  ]);

  useEffect(() => {
    if (parentHeight && sectionRef.current) {
      const sectionItemsContainer = sectionRef.current;
      const sectionItems = Array.from(
        sectionItemsContainer.children as HTMLCollection
      );

      sectionItems.forEach((child) => {
        const _child = child as HTMLElement;
        _child.style.fontSize = `calc(16px * ${sectionScale})`;
        if (_child.tagName.toLowerCase() !== 'div') {
          _child.style.paddingTop = `calc(5px * ${sectionScale})`;
        } else {
          _child.style.paddingTop = `calc(15px * ${sectionScale})`;
        }
        _child.style.paddingBottom = `calc(5px * ${sectionScale})`;
        const ctas = _child.querySelectorAll('a');
        ctas.forEach((c) => {
          c.style.fontSize = 'inherit';
          c.style.lineHeight = `calc(1.5 * ${sectionScale})`;
        });
        const texts = _child.querySelectorAll('p');
        texts.forEach((t) => {
          t.style.fontSize = 'inherit';
          t.style.lineHeight = `calc(1.5 * ${sectionScale})`;
        });
      });
    }
  }, [parentHeight, sectionScale]);

  const renderSections = () => {
    if (!props.items || props.items?.length === 0) return <></>;
    return (
      <>
        {props.items?.map((item, idx) => (
          <Fragment key={idx}>
            {item.contentType === 'NavItem' && !(item as INavItem).hidden ? (
              <a
                className={cn(styles.NavItemSection, {
                  [styles.NavItemSectionActive]: idx === selectedIdx,
                  [styles.NavItemSectionActiveTablet]:
                    isTablet && idx === selectedIdx,
                })}
                onMouseEnter={() => {
                  if (!isTablet) {
                    setSelectedIdx(idx);
                  }
                }}
                href={(item as INavItem).link?.url}
                target={(item as INavItem).link?.target || '_self'}
                onClick={(e) => {
                  if (isTablet && selectedIdx !== idx) {
                    e.preventDefault();
                    setSelectedIdx(idx);
                  }
                }}
              >
                <div>{(item as INavItem).link?.name}</div>
              </a>
            ) : item.contentType === 'BasicRte' ? (
              <div
                className={cn(styles.NavItemSection, styles.NavItemSectionRte)}
                dangerouslySetInnerHTML={{
                  __html: (item as IBasicRte).text as string,
                }}
              />
            ) : (
              <></>
            )}
          </Fragment>
        ))}
        <div className={styles.NavItemSectionCtas}>
          <MegaNavCtas ctas={props.ctas} />
        </div>
      </>
    );
  };

  const renderSectionPreviews = (items?: INavItem[]) => {
    if (!items || items?.length === 0) return <></>;
    return (
      <>
        {items
          ?.filter((x) => !x.hidden)
          ?.map((i, idx) => (
            <Fragment key={idx}>
              {idx === selectedIdx && (
                <div className={cn(styles.NavItemSectionPreview)}>
                  <HeroCardBlock areas={i.preview} _gridColumnCount={12} />
                </div>
              )}
            </Fragment>
          ))}
      </>
    );
  };

  return (
    <div ref={ref} className={cn(ancestorStyles.MegaNavItemBlock, styles.Nav)}>
      <Dialog
        open={open && props.idx === openIndex}
        onOpenChange={setOpen}
        root={ref.current}
      >
        <DialogTrigger
          className={cn(ancestorStyles.MainNav, styles.DialogTriggerOverride, {
            [ancestorStyles.NavActive]: openIndex === props.idx,
          })}
          onMouseEnter={() => {
            setOpenIndex(props.idx);
            setOpen(true);
          }}
          onClickComplete={() => {
            setOpenIndex(props.idx);
            setOpen(true);
          }}
        >
          {props.text}
        </DialogTrigger>
        <DialogContent
          className={styles.DialogOverlayOverride}
          contentClassName={styles.DialogOverlayContentOverride}
          disableFocusOnDialog
          hideCloseButton
        >
          <div
            className={styles.NavContents}
            ref={parentRef}
            style={{ height: `${parentHeight}px` }}
          >
            <div className={styles.NavItemSections} ref={sectionRef}>
              {renderSections()}
            </div>
            <div
              className={cn(styles.NavItemSectionPreviews, {
                [styles.NavItemSectionPreviewsTablet]: isTablet,
              })}
              ref={previewRef}
            >
              {renderSectionPreviews(props.items)}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
});

interface ModalProps {
  closeModal: () => void;
  items: (IBasicRte | INavItem)[];
  index: number;
  ctas?: CallToAction[];
}

const NavMobileModalContent: React.FC<ModalProps> = ({
  closeModal,
  items,
  ctas,
}) => {
  const { openMobileDialogIdx } = React.useContext(
    MegaNavContext
  ) as MegaNavContextType;

  useEffect(() => {
    if (openMobileDialogIdx < 1) {
      closeModal();
    }
  }, [openMobileDialogIdx]);

  return (
    <>
      {items?.map((item, idx) => (
        <Fragment key={idx}>
          {item.contentType === 'NavItem' && !(item as INavItem).hidden ? (
            <a
              className={cn(styles.NavItemSection)}
              href={(item as INavItem).link?.url}
              target={(item as INavItem).link?.target || '_self'}
            >
              {(item as INavItem).link?.name}
            </a>
          ) : item.contentType === 'BasicRte' ? (
            <div
              className={cn(styles.NavItemSection, styles.NavItemSectionRte)}
              dangerouslySetInnerHTML={{
                __html: (item as IBasicRte).text as string,
              }}
            />
          ) : (
            <></>
          )}
        </Fragment>
      ))}
      <div className={styles.NavItemSectionCtas}>
        <MegaNavCtas ctas={ctas} />
      </div>
    </>
  );
};

const NavMobileModal: React.FC<ModalProps> = (props) => {
  return (
    <Modal
      type="media"
      closeModal={props.closeModal}
      modalContainerClass={styles.MobileModalContainer}
      modalContentClass={styles.MobileModalContent}
      modalWrapperClass={styles.MobileModalWrapper}
      closeClickModal={false}
      lockScroll
      hideClose
    >
      <NavMobileModalContent {...props} />
    </Modal>
  );
};

export const NavMobile = memo((props: INav & { idx: number }) => {
  const { setOpenMobileDialogIdx, setOpenIndex } = React.useContext(
    MegaNavContext
  ) as MegaNavContextType;

  const portalManager = usePortals();
  const parentRef = useRef<HTMLDivElement>(null);

  const showModal = () => {
    portalManager.open(
      (portal) => (
        <NavMobileModal
          closeModal={portal.close}
          index={props.idx}
          items={props.items || []}
          ctas={props.ctas}
        />
      ),
      parentRef.current ? { appendTo: parentRef.current } : {}
    );
  };

  return (
    <div ref={parentRef} className={cn(styles.NavMobile)}>
      <div
        className={styles.NavMobileTrigger}
        onClick={() => {
          showModal();
          setOpenMobileDialogIdx(1);
          setOpenIndex(props.idx);
          digitalData.pushWithCommonData(
            {
              event: '_formNavigate',
              form: {
                name: 'mega nav',
                stage: removeSpecialCharacters(props.text),
              },
            },
            true
          );
        }}
      >
        <div>
          {props.icon && (
            <SvgIcon
              type={toCamelCase(props.icon) as keyof typeof IconEnum}
              color={`#${colorBlack}`}
            />
          )}
        </div>
        <div>{props.text}</div>
        <div>
          {props.icon && (
            <SvgIcon type="chevronRight" size={1.1} color={`#${colorBlack}`} />
          )}
        </div>
      </div>
    </div>
  );
});
