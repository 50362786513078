import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import {
  IModelCategory,
  IModelNav,
  IModelNavItem,
  IUsedCarsItem,
  MegaNavContextType,
} from '../../store';
import styles from './index.module.scss';
import ancestorStyles from '../../index.module.scss';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '~/common/components/ui-elements/floating-dialog';
import { MegaNavContext } from '../..';
import { HeroCardBlock } from '~/macros/hero-card-v2';
import MegaNavCtas from '../ctas';
import { IconEnum, Modal, SvgIcon } from '~/common/components/ui-elements';
import { colorBlack, toCamelCase } from '~/common/utils';
import digitalData from '~/common/services/data-layer-service';
import { DynamicTag } from '~/common/components/ui-elements/dynamic-tag';
import { usePortals } from 'react-portal-hook';
import { CallToAction, Link } from '~/common/models';

export const ModelNav = memo((props: IModelNav & { idx: number }) => {
  const { openIndex, setOpenIndex, isTablet } = React.useContext(
    MegaNavContext
  ) as MegaNavContextType;
  const ref = useRef<HTMLDivElement | null>(null);
  const [parentHeight, setParentHeight] = useState<number | null>(null);
  const [modelScale, setModelScale] = useState(1);
  const [sectionScale, setSectionScale] = useState(1);
  const parentRef = useRef<HTMLDivElement | null>(null);
  const modelRef = useRef<HTMLDivElement | null>(null);
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const previewRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [hideSection, setHideSection] = useState(false);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const usedCarsSectionIdx = props.items?.length ?? 0;
  const [selectedSectionIdx, setSelectedSectionIdx] =
    useState(usedCarsSectionIdx);
  const [category, setCategory] = useState('');
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setOpen(false);
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
        setParentHeight(null);
        setModelScale(1);
        setSectionScale(1);
      };

      handleResize();

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (open) {
      digitalData.pushWithCommonData(
        {
          event: '_formNavigate',
          form: {
            name: 'mega nav',
            stage: 'models',
          },
        },
        true
      );
    } else {
      setOpenIndex(-1);
    }
  }, [open]);

  const calculateMinHeight = useCallback(() => {
    const vh = windowSize.height;
    return Math.max(0, vh * (isTablet ? 0.6 : 0.8) - 125); // 125px for desktop/tablet offset only, since no preview for mobile
  }, [windowSize.height]);

  const calculateMaxHeight = useCallback(() => {
    const vh = windowSize.height;
    return Math.max(0, vh * 0.8 - 125); // 125px for desktop/tablet offset only, since no preview for mobile
  }, [windowSize.height]);

  useEffect(() => {
    if (
      parentRef.current &&
      modelRef.current &&
      sectionRef.current &&
      previewRef.current
    ) {
      const tallestChildHeight = Math.max(
        modelRef.current.scrollHeight,
        sectionRef.current.scrollHeight,
        previewRef.current.scrollHeight
      );
      const minHeight = calculateMinHeight();
      const maxHeight = calculateMaxHeight();
      const calculatedHeight = Math.min(
        Math.max(tallestChildHeight, minHeight),
        maxHeight
      );
      setParentHeight(calculatedHeight);
      if (modelRef.current.scrollHeight > calculatedHeight)
        setModelScale((prevScale) => prevScale - 0.01);
      if (sectionRef.current.scrollHeight > calculatedHeight)
        setSectionScale((prevScale) => prevScale - 0.01);
    }
  }, [
    parentRef.current?.offsetHeight,
    modelRef.current?.scrollHeight,
    sectionRef.current?.scrollHeight,
    previewRef.current?.scrollHeight,
    calculateMinHeight,
    calculateMaxHeight,
  ]);

  useEffect(() => {
    if (parentHeight && modelRef.current && sectionRef.current) {
      const modelItemsContainer = modelRef.current;
      const modelItems = Array.from(
        modelItemsContainer.children as HTMLCollection
      );
      const sectionItemsContainer = sectionRef.current;
      const sectionItems = Array.from(
        sectionItemsContainer.children as HTMLCollection
      );

      modelItems.forEach((child) => {
        const _child = child as HTMLElement;
        _child.style.height = `calc(60px * ${modelScale})`;
        const textContainer = _child.querySelector('div') as HTMLDivElement;
        textContainer.style.fontSize = `calc(16px * ${modelScale})`;
        const divs = textContainer.querySelectorAll('div:last-child');
        divs.forEach((d) => ((d as HTMLElement).style.fontSize = 'inherit'));
        const ctas = _child.querySelectorAll('a');
        ctas.forEach((c) => (c.style.fontSize = 'inherit'));
      });

      sectionItems.forEach((child) => {
        const _child = child as HTMLElement;
        _child.style.fontSize = `calc(14px * ${sectionScale})`;
        _child.style.gap = `calc(10px * ${sectionScale})`;
        const title = _child.querySelector('div:first-child') as HTMLDivElement;
        title.style.fontSize = `calc(22px * ${sectionScale})`;
        const ctas = _child.querySelectorAll('a');
        ctas.forEach((c) => {
          c.style.fontSize = 'inherit';
          c.style.lineHeight = `calc(1.5 * ${sectionScale})`;
        });
      });
    }
  }, [parentHeight, modelScale, sectionScale]);

  const hasUsedCarsItems =
    !props.usedCarsHideSubmenu &&
    !!props.usedCarsItems?.length &&
    props.usedCarsItems?.filter((x) => !x.hidden)?.length > 0;

  const renderModels = (items?: IModelNavItem[]) => (
    <>
      {items?.map((i, idx) => (
        <DynamicTag
          as={!!i.hideSubmenu ? 'a' : 'div'}
          key={idx}
          href={!!i.hideSubmenu ? i.link?.url : undefined}
          target={!!i.hideSubmenu ? i.link?.target || '_self' : undefined}
          className={cn(styles.ModelNavItem, {
            [styles.ModelNavItemActive]: idx === selectedIdx,
            [styles.ModelNavItemHidden]:
              !i.categories?.includes(category) && category,
          })}
          onMouseEnter={() => {
            if (!isTablet) {
              setSelectedIdx(idx);
            }
          }}
          onClick={(e) => {
            if (isTablet && selectedIdx !== idx) {
              e.preventDefault();
              setSelectedIdx(idx);
            }
          }}
        >
          <img src={i.imageSrc} />
          <div>
            <div>{i.modelName}</div>
            {i.subheading &&
              !i.categoriesToHideSubheading?.includes(category) && (
                <div>{i.subheading}</div>
              )}
          </div>
        </DynamicTag>
      ))}
      {!props.usedCarsHide && (
        <DynamicTag
          as={!hasUsedCarsItems ? 'a' : 'div'}
          key={usedCarsSectionIdx}
          href={!hasUsedCarsItems ? props.usedCarsUrl?.url : undefined}
          target={
            !hasUsedCarsItems ? props.usedCarsUrl?.target || '_self' : undefined
          }
          className={cn(styles.ModelNavItem, styles.UsedCars, {
            [styles.ModelNavItemActive]: usedCarsSectionIdx === selectedIdx,
            [styles.UsedCarsTablet]: isTablet,
            [styles.NoUsedCarsItem]: !hasUsedCarsItems,
          })}
          onMouseEnter={() => {
            if (!isTablet) {
              setSelectedIdx(usedCarsSectionIdx);
            }
          }}
          onClick={(e) => {
            if (isTablet && selectedIdx !== usedCarsSectionIdx) {
              e.preventDefault();
              setSelectedIdx(usedCarsSectionIdx);
            }
          }}
        >
          <img src={props.usedCarsImageSrc} />
          <div>{props?.usedCarsUrl?.name}</div>
        </DynamicTag>
      )}
      <div className={styles.ModelNavCtas}>
        <MegaNavCtas ctas={props.ctas} />
      </div>
    </>
  );

  const renderSections = (items?: IModelNavItem[]) => {
    return (
      <>
        {items?.map((i, idx) => (
          <div
            key={idx}
            className={cn(styles.ModelNavItemSection)}
            style={{
              ...(idx === selectedIdx && !i.hideSubmenu
                ? ''
                : { display: 'none' }),
            }}
          >
            <div>
              <a href={i.link?.url} target={i.link?.target || '_self'}>{`${
                i.modelName
              }${
                i.subheading &&
                !i.categoriesToHideSubheading?.includes(category)
                  ? ` (${i.subheading})`
                  : ''
              }`}</a>
            </div>
            {i.items?.map((j, idx2) => (
              <a
                key={idx2}
                className={cn(styles.ItemLink)}
                href={j?.url}
                target={j?.target || '_self'}
              >
                {j?.name}
              </a>
            ))}
            <MegaNavCtas ctas={i.ctas} />
          </div>
        ))}
        {hasUsedCarsItems && (
          <div
            key={usedCarsSectionIdx}
            className={cn(styles.ModelNavItemSection)}
            style={{
              ...(usedCarsSectionIdx === selectedIdx
                ? ''
                : { display: 'none' }),
            }}
          >
            <div> {props.usedCarsUrl?.name ?? 'Used Cars'}</div>
            {props.usedCarsItems
              ?.filter((x) => !x.hidden)
              ?.map((item, idx2) => (
                <Fragment key={(items?.length || 0) + idx2}>
                  {item.contentType === 'UsedCarsItem' && (
                    <a
                      className={cn(styles.ItemLink, {
                        [styles.ItemLinkActive]:
                          selectedSectionIdx === (items?.length || 0) + idx2,
                      })}
                      href={item.link?.url}
                      target={item.link?.target || '_self'}
                      onMouseEnter={() => {
                        setSelectedSectionIdx((items?.length || 0) + idx2);
                      }}
                    >
                      {item.link?.name}
                    </a>
                  )}
                </Fragment>
              ))}
            <MegaNavCtas ctas={props.usedCarsCtas} />
          </div>
        )}
      </>
    );
  };

  const renderSectionPreviews = (items?: IModelNavItem[]) => (
    <>
      {items?.map((i, idx) => (
        <div
          key={idx}
          className={cn(styles.ModelNavItemSectionPreview)}
          style={{
            ...(idx === selectedIdx ? '' : { display: 'none' }),
          }}
        >
          <HeroCardBlock areas={i.preview} _gridColumnCount={12} />
        </div>
      ))}
      {!props.usedCarsHide && (
        <>
          {!!props.usedCarsHideSubmenu ? (
            <div
              className={cn(styles.ModelNavItemSectionPreview)}
              style={{
                ...(usedCarsSectionIdx === selectedIdx
                  ? ''
                  : { display: 'none' }),
              }}
            >
              <HeroCardBlock
                areas={props.usedCarsPreview}
                _gridColumnCount={12}
              />
            </div>
          ) : (
            props.usedCarsItems
              ?.filter((x) => !x.hidden)
              ?.map((item, idx) => (
                <div
                  key={(items?.length || 0) + idx}
                  className={cn(styles.ModelNavItemSectionPreview)}
                  style={{
                    ...((items?.length || 0) + idx === selectedSectionIdx &&
                    usedCarsSectionIdx === selectedIdx
                      ? ''
                      : { display: 'none' }),
                  }}
                >
                  <HeroCardBlock
                    areas={item.preview ?? props.usedCarsPreview}
                    _gridColumnCount={12}
                  />
                </div>
              ))
          )}
        </>
      )}
    </>
  );

  const renderCategories = (
    categories?: IModelCategory[],
    items: IModelNavItem[] = []
  ) => {
    if (!categories || categories?.length === 0) return <></>;

    const handleCategoryChange = (c: string) => {
      setCategory(c);
      const showIdx = items.findIndex((f) => f.categories?.includes(c) || !c);
      setSelectedIdx(showIdx);
    };

    return (
      <>
        {categories.map((c, idx) => (
          <div key={idx} className={styles.Category}>
            <DynamicTag
              as={c.link?.url ? 'a' : 'div'}
              href={c.link?.url ? c.link.url : undefined}
              target={c.link?.url ? c.link.target || '_self' : undefined}
              className={cn(styles.CategoryText, {
                [styles.CategoryTextActive]:
                  category === c.text || (!category && idx === 0),
              })}
              onMouseEnter={() => handleCategoryChange(c.text)}
            >
              {c.text}
            </DynamicTag>
          </div>
        ))}
      </>
    );
  };

  const isSectionHidden = !!(
    props.items &&
    props.items.length > 0 &&
    selectedIdx >= 0 &&
    props.items[selectedIdx]?.hideSubmenu
  );

  useEffect(() => {
    const _hideUsedCarsSection =
      usedCarsSectionIdx === selectedIdx && !!props.usedCarsHideSubmenu;
    setHideSection(isSectionHidden || _hideUsedCarsSection);
  }, [selectedIdx]);

  return (
    <div
      ref={ref}
      className={cn(ancestorStyles.MegaNavItemBlock, styles.ModelNav)}
    >
      <Dialog
        open={open && props.idx === openIndex}
        onOpenChange={setOpen}
        root={ref.current}
      >
        <DialogTrigger
          className={cn(ancestorStyles.MainNav, styles.DialogTriggerOverride, {
            [ancestorStyles.NavActive]: openIndex === props.idx,
          })}
          onMouseEnter={() => {
            setOpenIndex(props.idx);
            setOpen(true);
          }}
          onClickComplete={() => {
            setOpenIndex(props.idx);
            setOpen(true);
          }}
        >
          {props.text}
        </DialogTrigger>
        <DialogContent
          className={styles.DialogOverlayOverride}
          contentClassName={styles.DialogOverlayContentOverride}
          disableFocusOnDialog
          hideCloseButton
        >
          <div className={styles.ModelNavCategories}>
            {renderCategories(
              props.categories,
              props.items?.filter((x) => !x.hidden)
            )}
          </div>
          <div
            className={styles.ModelNavContents}
            ref={parentRef}
            style={{
              height: `${parentHeight}px`,
              minHeight: `${parentHeight}px`,
              maxHeight: `${parentHeight}px`,
            }}
          >
            <div className={styles.ModelNavItems} ref={modelRef}>
              {renderModels(props.items?.filter((x) => !x.hidden))}
            </div>
            <div
              className={cn(styles.ModelNavItemSections, {
                [styles.ModelNavItemSectionsHidden]: hideSection,
              })}
              ref={sectionRef}
            >
              {renderSections(props.items?.filter((x) => !x.hidden))}
            </div>
            {(!isTablet ||
              (isTablet &&
                selectedIdx === usedCarsSectionIdx &&
                !hasUsedCarsItems) ||
              (isTablet && isSectionHidden)) && (
              <div
                className={cn(styles.ModelNavItemSectionPreviews, {
                  [styles.ModelNavItemSectionPreviewsTablet]: isTablet,
                  [styles.ModelNavItemSectionPreviewsExpand]: hideSection,
                })}
                ref={previewRef}
              >
                {renderSectionPreviews(props.items?.filter((x) => !x.hidden))}
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
});

interface ModalProps {
  closeModal: () => void;
  items: IModelNavItem[];
  categories: IModelCategory[];
  usedCarsUrl?: Link;
  ctas?: CallToAction[];
  usedCarsItems?: IUsedCarsItem[];
  usedCarsHide?: boolean;
  usedCarsHideSubmenu?: boolean;
}

const ModelNavMobileModalContent: React.FC<ModalProps> = ({
  closeModal,
  categories,
  items,
  ctas,
  usedCarsUrl,
  usedCarsHide,
  usedCarsHideSubmenu,
  usedCarsItems,
}) => {
  const { openMobileDialogIdx } = React.useContext(
    MegaNavContext
  ) as MegaNavContextType;
  const [category, setCategory] = useState('');

  useEffect(() => {
    if (openMobileDialogIdx < 1) {
      closeModal();
    }
  }, [openMobileDialogIdx]);

  const renderModels = (_items?: IModelNavItem[]) => {
    if (!_items || _items?.length === 0) return <></>;

    return (
      <>
        {_items?.map((i, idx) => (
          <div
            key={idx}
            className={cn(styles.ModelNavItem, {
              [styles.ModelNavItemHidden]:
                !i.categories?.includes(category) && category,
            })}
          >
            <div>
              <img src={i.imageSrc} />
              <a href={i.link?.url} target={i.link?.target || '_self'}>
                <div>
                  <div>{`${i.modelName}`}</div>
                  {i.subheading && <div>{i.subheading}</div>}
                </div>
              </a>
            </div>
          </div>
        ))}
        {!usedCarsHide && (
          <>
            {usedCarsItems &&
            usedCarsItems.length > 0 &&
            !usedCarsHideSubmenu ? (
              usedCarsItems.map((i, idx) => (
                <a
                  key={(_items.length || 0) + idx}
                  href={i.link?.url}
                  target={i.link?.target || '_self'}
                  className={cn(styles.ModelNavItem, styles.UsedCars)}
                >
                  {i.link?.name}
                </a>
              ))
            ) : (
              <a
                key={_items.length || 0}
                href={usedCarsUrl?.url}
                target={usedCarsUrl?.target || '_self'}
                className={cn(styles.ModelNavItem, styles.UsedCars)}
              >
                {usedCarsUrl?.name}
              </a>
            )}
          </>
        )}
        <MegaNavCtas ctas={ctas} />
      </>
    );
  };

  const renderCategories = (_categories?: IModelCategory[]) => {
    if (!_categories || _categories?.length === 0) return <></>;

    const handleCategoryClick = (c: string) => setCategory(c);

    return (
      <>
        {_categories.map((c, idx) => (
          <div key={idx} className={styles.Category}>
            <DynamicTag
              as={c.link?.url ? 'a' : 'div'}
              href={c.link?.url ? c.link.url : undefined}
              target={c.link?.url ? c.link.target || '_self' : undefined}
              className={cn(styles.CategoryText, {
                [styles.CategoryTextActive]:
                  category === c.text || (!category && idx === 0),
              })}
              onClick={() => handleCategoryClick(c.text)}
            >
              {c.text}
            </DynamicTag>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <div className={styles.ModelNavCategories}>
        {renderCategories(categories)}
      </div>
      <div className={styles.ModelNavContents}>
        <div className={styles.ModelNavItems}>{renderModels(items)}</div>
      </div>
    </>
  );
};

const ModelNavMobileModal: React.FC<ModalProps> = (props) => {
  return (
    <Modal
      type="media"
      closeModal={props.closeModal}
      modalContainerClass={styles.MobileModalContainer}
      modalContentClass={styles.MobileModalContent}
      modalWrapperClass={styles.MobileModalWrapper}
      closeClickModal={false}
      lockScroll
      hideClose
    >
      <ModelNavMobileModalContent {...props} />
    </Modal>
  );
};

export const ModelNavMobile = memo((props: IModelNav & { idx: number }) => {
  const { setOpenMobileDialogIdx, setOpenIndex } = React.useContext(
    MegaNavContext
  ) as MegaNavContextType;

  const portalManager = usePortals();
  const parentRef = useRef<HTMLDivElement>(null);

  const showModal = () => {
    portalManager.open(
      (portal) => (
        <ModelNavMobileModal
          closeModal={portal.close}
          items={props.items?.filter((x) => !x.hidden) || []}
          categories={props.categories || []}
          ctas={props.ctas}
          usedCarsUrl={props.usedCarsUrl}
          usedCarsHide={props.usedCarsHide}
          usedCarsHideSubmenu={props.usedCarsHideSubmenu}
          usedCarsItems={props.usedCarsItems}
        />
      ),
      parentRef.current ? { appendTo: parentRef.current } : {}
    );
  };

  return (
    <div className={cn(styles.ModelNavMobile)}>
      <div
        className={styles.ModelNavMobileTrigger}
        onClick={() => {
          showModal();
          setOpenMobileDialogIdx(1);
          setOpenIndex(props.idx);
          digitalData.pushWithCommonData(
            {
              event: '_formNavigate',
              form: {
                name: 'mega nav',
                stage: 'models',
              },
            },
            true
          );
        }}
      >
        <div>
          {props.icon && (
            <SvgIcon
              type={toCamelCase(props.icon) as keyof typeof IconEnum}
              color={`#${colorBlack}`}
            />
          )}
        </div>
        <div>{props.text}</div>
        <div>
          {props.icon && (
            <SvgIcon type="chevronRight" size={1.1} color={`#${colorBlack}`} />
          )}
        </div>
      </div>
    </div>
  );
});
